import { GetStaticProps, GetStaticPropsContext } from 'next'
import Image from 'next/image'
import React from 'react'
import { Layout } from '../container/Layout'
import styles from '../styles/index.module.scss'

interface Props {
	locale: string
}

export default function Home({ locale }: Props): JSX.Element {
	return (
		<>
			<div className={styles.background}>
				<Image src="/images/background.jpg" alt="" layout="fill" objectFit="contain" quality={80} priority />
			</div>
			<Layout>
				<div className="inner">
					<header className={styles.header}>
						<h1 className={styles.title}>FUCHSFOX</h1>
					</header>
					{locale === 'en' ? (
						<p className={styles.description}>
							niklas pelle fuchs (art director) + justin fox (software engineer) - Freelancers from Hamburg and Berlin.
							Our focus is logos, corporate designs, advertising, websites, apps, front- and backend development. We are
							connected with copywriters, SEO experts, motion designers, filmmakers, sound designers etc. and make
							offers individually according to need and scope.
						</p>
					) : (
						<p className={styles.description}>
							niklas pelle fuchs (art director) + justin fox (software engineer) – Freelancer aus Hamburg und Berlin.
							Unser Fokus sind Logos, Corporate Designs, Werbung, Websites, Apps, Front- und Backend-Entwicklung. Wir
							sind verbunden mit Textern, SEO-Experten, Motion Designern, Filmern, Sound Designern etc und stellen
							Angebote individuell nach Bedarf und Umfang auf.
						</p>
					)}
				</div>
			</Layout>
		</>
	)
}

export const getStaticProps: GetStaticProps = async ({ locale }: GetStaticPropsContext) => {
	return {
		props: {
			locale,
		},
	}
}
